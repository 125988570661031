.jumbotron {
  color: white;
  background-image: url('/../assets/coarkboard.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

}

.companies {
  background-image: url('/../assets/architecture.jpg');
}

team {
  background: #eee !important;
}

section {
  padding: 60px 0;
}

section .section-title {
  text-align: center;
  color: #007b5e;
  margin-bottom: 50px;
  text-transform: uppercase;
}

#team .card {
  //border: none;
  background: #ffffff;
}

.image-flip:hover .backside,
.image-flip.hover .backside {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  transform: rotateY(0deg);
  border-radius: 0.25rem;
}

.image-flip:hover .frontside,
.image-flip.hover .frontside {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.mainflip {
  -webkit-transition: 1s;
  -webkit-transform-style: preserve-3d;
  -ms-transition: 1s;
  -moz-transition: 1s;
  -moz-transform: perspective(1000px);
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transition: 1s;
  transform-style: preserve-3d;
  position: relative;
}

.frontside {
  position: relative;
  -webkit-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  z-index: 2;
  margin-bottom: 30px;
}

.backside {
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  -webkit-transform: rotateY(-180deg);
  -moz-transform: rotateY(-180deg);
  -o-transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  -webkit-box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
  -moz-box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
  box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
}

.frontside,
.backside {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: 1s;
  -webkit-transform-style: preserve-3d;
  -moz-transition: 1s;
  -moz-transform-style: preserve-3d;
  -o-transition: 1s;
  -o-transform-style: preserve-3d;
  -ms-transition: 1s;
  -ms-transform-style: preserve-3d;
  transition: 1s;
  transform-style: preserve-3d;
}

.frontside .card,
.backside .card {
  min-height: 312px;
}

.backside .card a {
  font-size: 18px;
  color: #007b5e !important;
}

.frontside .card .card-title,
.backside .card .card-title {
  color: #007b5e !important;
}

.frontside .card .card-body img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}
.section_bg_gray {
  background-color: #f3f5f7;
}

.single_what_we_do {
  background-color: #ffffff;
  text-align: center;
  padding: 0 35px;
  border-radius: 10px;
  position: relative;
  margin-bottom: 30px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.single_what_we_do:hover {
  -webkit-box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.2);
}

.single_what_we_do .top_line {
  position: absolute;
  top: 0;
  left: 44%;
  background-color: $primary;
  width: 12%;
  height: 3px;
}

.single_what_we_do .what_we_do_figure {
  padding: 40px 0;
}

.single_what_we_do .what_we_do_figure img {
  margin: 0 auto;
}

.single_what_we_do .what_we_do_title {
  font-weight: 600;
  font-size: 22px;
  padding-bottom: 35px;
  margin: 0;
  color: #2b353a;
}

.single_what_we_do .what_we_do_content {
  padding-bottom: 65px;
  color: #2b353a;
}

.single_what_we_do .what_we_do_icon {
  position: absolute;
  bottom: -9px;
  left: 47%;
  color: #fff;
  background-color: $primary;
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 50%;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.single_what_we_do .what_we_do_icon i {
  font-size: 24px;
  text-align: center;
  padding: 2px 3px 2px 5px;
}
