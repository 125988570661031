/*------------------------------------
  Custombox
------------------------------------*/

// Enabled content scrolling in the open modal window to avoid shaking the main content when opening/closing the modal window.
.custombox-lock {
	overflow: auto;
}

.u-custombox-no-scroll.custombox-lock {
  margin-right: 1.0625rem; // To avoid page shaking issue
  overflow: hidden;
}