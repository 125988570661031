/*------------------------------------
  Divider Sizes
------------------------------------*/

/* Extra Small */
.u-ver-divider {
  &--xs {
    &::after {
      top: 50%;
      height: ($font-size-base * 1);
      transform: translateY(-50%);
    }
  }
}

/* Small */
.u-ver-divider {
  &--sm {
    &::after {
      top: 50%;
      height: ($font-size-base * 2);
      transform: translateY(-50%);
    }
  }
}

/* Large */
.u-ver-divider {
  &--lg {
    &::after {
      top: 50%;
      height: ($font-size-base * 3);
      transform: translateY(-50%);
    }
  }
}

/* Extra Large */
.u-ver-divider {
  &--xl {
    &::after {
      top: 50%;
      height: ($font-size-base * 4);
      transform: translateY(-50%);
    }
  }
}