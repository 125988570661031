/*********footer*******************/


.foo_top_header_one {
  background-color: #15151e;
  color: #fff;
}

.section_padding_100_70 {
  padding-top: 100px;
  padding-bottom: 70px;
}

.foo_top_header_one {
  color: #fff;
}

.foo_top_header_one .kilimanjaro_part > h5 {
  color: #fff;
}

p, ul li, ol li {
  font-weight: 300;
}

.section_padding_50 {
  padding: 50px 0;
}

p, ul li, ol li {
  font-weight: 300;
}

a, h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
}
