/*------------------------------------
  Slick Pagination Interactive
------------------------------------*/

.u-slick--pagination-interactive {
  &__text {
    color: $white-color-70;
  }

  .slick-slide {
    cursor: pointer;
    color: $white;
    background: rgba($white, .1);
    transition: $transition-timing;

    &:hover {
      background: rgba($white, .15);

      &.slick-center {
        background: $white;
      }
    }
  }

  .slick-center {
    background: $white;

    .u-slick--pagination-interactive__title {
      color: $primary;
    }

    .u-slick--pagination-interactive__text {
      color: $secondary;
    }
  }
}