

@-webkit-keyframes three-quarters {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }

  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn)
  }
}

@keyframes three-quarters {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }

  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn)
  }
}


button .btn-spinner {
  font-family: sans-serif;
  font-weight: 100;
  -webkit-animation: three-quarters 1.25s infinite linear;
  animation: three-quarters 1.25s infinite linear;
  border: 2px solid #fff;
  border-right-color: transparent;
  border-radius: 100%;
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  overflow: hidden;
  text-indent: -9999px;
  width: 13px;
  height: 13px;
}

button .btn-spinner:not(:required) {
  margin-left: -13px;
  opacity: 0;
  transition: margin .4s ease-out,opacity .2s ease-out;
}

button.is-loading .btn-spinner {
  transition: margin .2s ease-in,opacity .4s ease-in;
  margin-left: 5px;
  opacity: 1;
}
