.sound-bar-contain{
  height: 200px;
  width: 30px;
  margin: 0 auto;
  border-radius: 4px;
  border: 1px solid #ddd;
  position: relative;
}


 .sound-bar-contain .sound-bar {
  background: #26C465;
  width: 100%;
  position: absolute;
  bottom: 0;
  border-radius: 0 0 4px 4px;
  transition: all 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
 }

 .camera-wrapper .OT_publisher{
   margin-left: auto;
   margin-right: auto;

 }
