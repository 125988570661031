/*------------------------------------
  Card
------------------------------------*/

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $prop, $abbrev in (column-count: cc) {
      @each $card-count, $length in $card-count {
        .card#{$infix}-#{$card-count}-count { #{$prop}: $length; }
      }
    }

    @each $prop, $abbrev in (column-gap: cg) {
      @each $card-gap-sizes, $gap in $card-gap-sizes {
        .card#{$infix}-#{$card-gap-sizes}-gap { #{$prop}: $gap; }
      }
    }

    .card#{$infix}-columns {
      orphans: 1;
      widows: 1;

      .card {
        display: inline-block; // Don't let them vertically span multiple columns
        width: 100%; // Don't let their width change
      }
    }
  }
}

/* Background Color */
.card-bg-light {
  background-color: $card-bg-light;

  &:hover {
    background-color: $card-bg-light-hover;
  }
}

/* Frame */
.card {
  &-frame {
    border: $card-frame-border-width $card-frame-border-style $card-frame-border-color;
    transition: $card-frame-transition;

    &-highlighted,
    &:hover {
      border-color: $card-frame-hover-border-color;
      box-shadow: $card-frame-hover-box-shadow;
    }
  }

  &-text-dark {
    color: $card-text-dark-color;

    &:hover {
      color: $card-text-dark-hover-color;
    }
  }
}

/* Collapse */
.card {
  &-collapse {
    padding: 0;
    transition: $card-collapse-transition;

    &:hover {
      background-color: $card-collapse-hover-bg-color;
    }

    .card-body {
      color: $card-body-color;
    }
  }

  &-btn {
    color: $card-btn-color;
    text-align: left;
    white-space: inherit;

    &-arrow {
      display: inline-block;
      color: $card-btn-arrow-color;
      margin-left: 1rem;
      transition: $card-btn-arrow-transition;

      .collapsed & {
        transform: $card-btn-arrow-collapsed-rotation;
      }
    }
  }
}

/* IE image rendering fix */
.card-img-top,
.card-img-bottom {
  min-height: 1px;
}