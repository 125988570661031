// Gradients

// Horizontal gradient, from left to right
//
// Creates two color stops, start and end, by specifying a color and position for each color stop.
@mixin gradient-x($hs-start-color: #555, $hs-end-color: #333, $hs-start-percent: 0%, $hs-end-percent: 100%, $deg: 90deg) {
  background-image: linear-gradient($deg, $hs-start-color $hs-start-percent, $hs-end-color $hs-end-percent);
  background-repeat: repeat-x;
}

// Vertical gradient, from top to bottom
//
// Creates two color stops, start and end, by specifying a color and position for each color stop.
@mixin gradient-y($hs-start-color: #555, $hs-end-color: #333, $hs-start-percent: 0%, $hs-end-percent: 100%, $deg: 0deg) {
  background-image: linear-gradient($deg, $hs-start-color $hs-start-percent, $hs-end-color $hs-end-percent);
  background-repeat: repeat-x;
}

@mixin gradient-x-three-colors($hs-start-color: #00b3ee, $hs-mid-color: #7a43b6, $hs-color-stop: 50%, $hs-end-color: #c3325f) {
  background-image: linear-gradient(to right, $hs-start-color, $hs-mid-color $hs-color-stop, $hs-end-color);
  background-repeat: no-repeat;
}
@mixin gradient-y-three-colors($hs-start-color: #00b3ee, $hs-mid-color: #7a43b6, $hs-color-stop: 50%, $hs-end-color: #c3325f) {
  background-image: linear-gradient($hs-start-color, $hs-mid-color $hs-color-stop, $hs-end-color);
  background-repeat: no-repeat;
}
@mixin gradient-radial($hs-inner-color: #555, $hs-outer-color: #333) {
  background-image: radial-gradient(circle, $hs-inner-color, $hs-outer-color);
  background-repeat: no-repeat;
}
@mixin gradient-striped($hs-color: rgba(255,255,255,.15), $hs-angle: 45deg) {
  background-image: linear-gradient($hs-angle, $hs-color 25%, transparent 25%, transparent 50%, $hs-color 50%, $hs-color 75%, transparent 75%, transparent);
}
