/*------------------------------------
  Slick Paging
------------------------------------*/

.u-slick__paging {
	position: absolute;
	bottom: 2rem;
	right: 0;
	padding-left: .9375rem;
	padding-right: .9375rem;

	.u-paging {
		&__current {
			color: $white;
			font-size: ($font-size-base * 3.875);
			font-weight: 300;
			line-height: 1;
		}

		&__divider {
			position: relative;
			margin-right: .5rem;
			margin-left: .25rem;

			&::before {
				position: absolute;
				top: 0;
				left: 0;
				color: $white-color-70;
				font-size: ($font-size-base * .875);
				content: "\002f";
			}
		}

		&__total {
			font-size: ($font-size-base * 1.5);
			color: $white-color-70;
		}
	}
}