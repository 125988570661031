/*------------------------------------
  Timeline
------------------------------------*/

.u-timeline {
  list-style: none;
  padding-left: 1rem;
  margin-bottom: 0;

  &__item {
    position: relative;
    padding-right: .9375rem;
    padding-left: 2rem;

    &:not(:last-child) {
      padding-bottom: 1.5rem;
      margin-bottom: 1.5rem;
    }

    &::before {
      position: absolute;
      top: 3.5rem;
      bottom: 0;
      left: 0;
      height: auto;
      border-left: .1875rem solid $gray-300;
      content: "";
    }
  }

  &__icon {
    position: absolute;
    left: -.875rem;
  }

  &__heading {
    position: relative;
    display: block;
    font-size: $h5-font-size;
    font-weight: $font-weight-medium;
    padding-bottom: .75rem;
    margin-bottom: 1rem;

    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      border-bottom: .1rem solid $gray-300;
      content: "";
    }
  }
}