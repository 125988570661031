

.contain-center {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  max-width: 855px;
  position: relative;
}

.setup-test--sub-header {
  max-width: 400px;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 35px;
  font-size: 10px;
}

.tile-setup {
  padding: 0 20px;
  overflow: visible;
}

.tile-setup h3 {
  font-size: 24px;
  font-weight: 500;
  color: #525252;
  position: relative;
}

.tile-setup h3 span {
  position: absolute;
  background: #fff;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.14), 0 1px 4px rgba(0, 0, 0, 0.25);
  left: -47px;
  width: 40px;
  height: 40px;
  text-align: center;
  padding-top: 3px;
  top: -8px;
}

.tile-setup p {
  color: #585858;
  font-size: 15px;
}

.tile {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.14), 0 0px 1px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  padding-top: 10px;
  *zoom: 1;
  border: 0 solid #e3e3e3;
  margin-bottom: 10px;
  background: #fff;
  position: relative;
  transition: box-shadow 250ms ease-out;
}

.setup-page--list-action {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: column;
  flex-flow: column;
  -ms-flex-align: end;
  align-items: flex-end;
}

.setup-page--list-action.setup-page--list-action-buttons {
  -ms-flex-pack: end;
  justify-content: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  -ms-flex-flow: row;
  flex-flow: row;
}

.setup-page--list-sub-actions {
  padding: 0;
  margin: 0;
  padding-bottom: 10px;
}
.setup-page--list-sub-action {
  list-style: none;
  margin: 0;
  background-color: #efefef;
  border: 1px solid #d5d5d5;
  border-radius: 3px;
  margin-bottom: 10px;
}

.setup-page--list-sub-action a {
  color: #555555;
  width: 100%;
  display: block;
  padding: 5px 10px;
  line-height: 30px;
}

.setup-page--list-action .text-muted {
  width: 100%;
}
