/*------------------------------------
  Header Fullscreen Style
------------------------------------*/

.u-fullscreen {
	// Nav
	&__nav {
		padding-left: 0;
	  list-style: none;
	  margin-bottom: 0;

		&-list {
		  list-style: none;
	  	padding-left: 0;
		  margin-bottom: 0;
		}

	  &-link {
	  	display: inline-block;
  		color: $white;
	  	font-size: ($font-size-base * 1.375);
			padding-top: .5rem;
			padding-bottom: .5rem;

			// Large Devices
			@include media-breakpoint-up(lg) {
	  		font-size: ($font-size-base * 1.75);
			}

	  	&:hover {
	  		color: $white-color-70;
	  	}
	  }
	}

	// Sub Menu
	&__submenu {
	  position: relative;
		transition: $transition-timing $transition-function;

		.u-fullscreen__nav-list {
			border-left: 2px solid rgba($white, .6);
			padding-left: 1rem;
		}

		&-nav-link {
			display: inline-block;
  		color: $white;
		  padding-top: .5rem;
			padding-bottom: .5rem;

	  	&:hover {
	  		color: $white-color-70;
	  	}
	  }

		&-list {
		  list-style: none;
			border-left: 2px solid rgba($white, .6);
			padding-left: 1rem;
		  margin-bottom: 0;

			&-link {
		  	display: inline-block;
	  		color: $white-color-70;

		  	&:hover {
	  			color: $white;
		  	}
		  }
		}
  }
}