/*------------------------------------
  Tagsinput
------------------------------------*/

.u-tagsinput {
  .bootstrap-tagsinput {
    width: 100%;
    border-color: $gray-300;
    @include border-radius($border-radius);
    box-shadow: none;
    padding: .25rem;
    padding-bottom: 0;

    &::before {
      content: "|";
      display: inline-block;
      width: 1px;
      line-height: 1;
      font-size: .625rem;
      opacity: 0;
      padding: .75rem 0;
    }

    .tag {
      position: relative;
      display: inline-block;
      font-size: .875rem;
      color: $secondary;
      background-color: rgba($secondary, .1);
      @include border-radius($border-radius);
      padding: .25rem 1.875rem .25rem .75rem;
      margin-bottom: .25rem;
      margin-right: 0;

      [data-role="remove"] {
        position: absolute;
        right: .5rem;
        top: 50%;
        color: $secondary;
        font-size: 1.25rem;
        outline: none;
        transform: translateY(-50%);

        &::after {
          content: "\00d7";
        }

        &:hover {
          color: $dark;
          box-shadow: none;
        }
      }
    }
  }
}