span.badge.InReview {
  @include badge-variant($warning);
}

span.badge.Rejected {
  @include badge-variant($danger);
}

span.badge.Draft {
  @include badge-variant($info);
}

span.badge.Published {
  @include badge-variant($success);
}

span.badge.PendingRecording {
  @include badge-variant($warning);
}

