.messages{
  max-height: 500px;
  overflow-y: scroll;
}
.messages ul{
  list-style: none;
  padding-left: 0;
}
.messages ul li {
  display: inline-block;
  clear: both;
  float: left;
  margin-left: 2px;
  width: calc(100% - 3px);
  font-size: 0.9em;
  padding-left: 0;
}


.messages ul li.sent p {
  background: #435f7a;
  color: #f5f5f5;
}

.messages ul li.replies p {
  float: right;
}

.messages ul li.replies span {

  width: 100%;
  text-align: right;
  display: block;
  padding-right: 2px;

}

 .messages ul li p {
  background: #dddddd;
  display: inline-block;
  padding: 10px 15px;
  border-radius: 20px;
  max-width: 75%;
  line-height: 130%;
}

.messages ul li p a {
  color: #fff;
  text-decoration: underline;
}

.nav-link{
  cursor: pointer;
}





