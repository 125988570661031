/*------------------------------------
  Avatar
------------------------------------*/

.u-avatar {
	width: $avatar;
	height: $avatar;
}

.u-xs-avatar {
  width: $avatar-xs;
  height: $avatar-xs;
}

.u-sm-avatar {
	width: $avatar-sm;
	height: $avatar-sm;
}

.u-lg-avatar {
  width: $avatar-lg;
  height: $avatar-lg;
}

.u-xl-avatar {
  width: $avatar-xl;
  height: $avatar-xl;
}