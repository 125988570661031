/*------------------------------------
  Blog Thumb Minimal
------------------------------------*/

.u-blog-thumb-minimal {
	padding: .5rem;
	transition: $transition-timing $transition-function;

	&,
	&__img {
		@include border-radius($border-radius);
	}

	&:hover {
		background-color: $primary-darker;
	}

	&__img-wrapper {
		width: 5rem;
	}
}