.hideScrollBars::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.hideScrollBars {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

#live-event {
  .main-container {
    background-color: #000;
  }

  .chat-sidebar {
    @include media-breakpoint-up(xl) {
      -ms-flex: 0 0 330px;
      flex: 0 0 330px;
      background-color: #333;
    }

    @include media-breakpoint-down(lg) {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      background-color: #fafafa;
    }
  }

  .video-container {
    @include media-breakpoint-up(xl) {
      height: calc(100vh - 60px);
    }
  }

  .live-player {
    @include media-breakpoint-up(xl) {
      height: calc(100% - 60px);
    }

    @include media-breakpoint-down(lg) {
      height: 100%;
    }
  }

  .live-player.presenter-getting-ready {
    @include media-breakpoint-up(xl) {
      height: calc(100% - 140px);
    }

    @include media-breakpoint-down(lg) {
      height: calc(50vh - 140px);
    }
  }

  .live-player-fluid {
    @include media-breakpoint-up(xl) {
      padding-top: 0px !important;
    }
  }

  .chat-collapsed {
    @include media-breakpoint-up(xl) {
      display: none;
    }
  }

  .hide-chat-tab {
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  .hide-when-small {
    @include media-breakpoint-down(lg) {
      visibility: hidden;
    }
  }

  .live-footer {
    @include media-breakpoint-up(xl) {
      position: absolute;
    }

    bottom: 0;
    width: 100%;
    height: 60px;
    line-height: 60px;
    background-color: #333;
  }

  .chat-button-wrapper {
    position: absolute;
    left: 290px;
    text-align: right;
    z-index: 9999999999;
  }

  .sponsor {
    position: absolute;

    left: 5px;
    z-index: 20;
    max-width: 500px;
    max-height: 100px;

    @include media-breakpoint-down(lg) {
      bottom: 0px;
    }

    @include media-breakpoint-up(xl) {
      bottom: 65px;
    }
  }

  .collapse-chat-btn {
    visibility: hidden;
  }

  .chat-sidebar:hover .collapse-chat-btn {
    @include media-breakpoint-up(xl) {
      visibility: visible;
    }
  }

  .live-event-link-active::after {
    content: '';
    display: block;
    width: 100%;
    border-bottom: 2px solid;
    margin: 0 auto;
    transition: all 0.3s linear 0s;
  }

  .popover-window {
    @include media-breakpoint-up(xl) {
      width: 600px;
      position: absolute;
      bottom: 60px;
      right: 333px;
    }

    z-index: 1050;

    @include media-breakpoint-down(lg) {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
    }
  }

  .popover-window div.card {
    @include media-breakpoint-down(lg) {
      // height: 400px;
    }
  }

  .popover-window header.card-header {
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  .popover-window div.card-body {
    height: calc((100vh / 2) - 100px);
    overflow-y: scroll;
    background-color: #fafafa;
  }

  .live-messages {
    overflow-y: scroll;

    @include media-breakpoint-down(lg) {
      padding: 10px;
      height: calc(100vh / 2);
    }
    @include media-breakpoint-up(xl) {
      height: calc(100vh - 120px);
      padding: 5px;
    }
  }

  .live-messages ul {
    list-style: none;
    padding-left: 0;
  }

  .live-messages ul li {
    display: inline-block;
    clear: both;
    float: left;
    margin-left: 2px;
    width: calc(100% - 3px);
    font-size: 0.9em;
    padding-left: 0;
  }
  .live-messages ul li.sent {
    margin-top: 10px;
  }

  .live-messages ul li.sent .avatar {
    float: right;
    margin-left: 5px;
    margin-top: -20px;
  }

  .system-message {
    img {
      display: none;
    }

    span {
      color: red;
    }

    .message-content {
      background-color: red;
    }
  }

  .live-messages ul li.replies .avatar {
    float: left;
    margin-right: 5px;
    margin-top: 0px;
  }

  .live-messages ul li.sent p {
    float: right;
    background: #ffffff;
    color: #000000;
  }

  .live-messages ul li.replies p {
    color: #000000;
  }

  .message-content {
    overflow-wrap: anywhere;
    word-break: break-word;

    @include media-breakpoint-down(lg) {
      padding: 8px 12px;
      line-height: 18px;
      font-size: 14px;
      word-wrap: break-word;
      white-space: pre-line;
      box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.03), 0px 1px 2px 0px rgba(0, 0, 0, 0.16);
      border-radius: 4px;
      background-color: #fff;
      position: relative;
      max-width: 100%;
    }
  }

  .reactions {
    padding: 0;
    margin-left: 35px;
    margin-top: -10px;
    margin-bottom: 10px;
  }

  .live-messages ul li.replies span {
    color: #999999;
    width: 100%;
    text-align: left;
    display: block;
    padding-right: 2px;
  }

  .live-messages ul li p {
    background: #fff;
    display: inline-block;
    padding: 10px 15px;
    border-radius: 5px;
    max-width: 75%;
    line-height: 130%;
  }

  .event-video-player {
    position: absolute;
    z-index: 10;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .live-messages ul li p a {
    text-decoration: underline;
  }

  .nowrap {
    flex-wrap: nowrap;
  }

  .poster {
    height: auto !important;
  }

  #webRTCLayoutContainer {
    position: relative;
    background-color: #fff;
    border-top: 1px solid #333;

    @include media-breakpoint-up(xl) {
      height: calc(100% - 60px);
    }

    @include media-breakpoint-down(lg) {
      height: calc(50vh - 60px);
    }
  }

  #webRTCLayoutContainer.greenroom, #webRTCLayoutContainer.presenter-getting-ready {
    @include media-breakpoint-up(xl) {
      height: calc(100% - 140px);
    }

    @include media-breakpoint-down(lg) {
      height: calc(50vh - 140px);
    }
  }

  #webRTCLayoutContainer > div {
    background-color: #000;
    display: inline-block;
    border: 1px solid #fff;

    transition-property: all;
    transition-duration: 0.5s;
  }

  #webRTCLayoutContainer > div {
    width: 0;
    height: 0;
    opacity: 0;
  }

  #webRTCLayoutContainer > div.OT_ignore {
    width: 100% !important;
    height: 100%  !important;
    opacity: 1 !important;
    margin-top: -24px;
  }

  #webRTCLayoutContainer > div.ot-layout {
    opacity: 1;
  }

  #videoPlayer div.vjs-loading-spinner{
    visibility: hidden !important;
  }

  .stream-controls {
    position: absolute;
    background-color: white;
    top: 5px;
    left: 20%;
    right: 20%;
  }

  .green-room-message {
    padding-bottom: 25px;
    padding-top: 25px;
    margin-bottom: 0;
    border-radius: 0;
    z-index: 50;
    width: 100%;
  }

  .no-chats {
    position: absolute;
    bottom: 46px;
    text-align: center;
    font-size: 2em;
    color: #989898;
    width: 100%;
    font-family: Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  .participant-list,
  .card-content {
    margin: 18px 15px 0px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
  }
  .participant-list > div:first-child {
    padding: 10px;
    background-color: white;
  }

  .participant-list ul {
    list-style: none;
    padding-left: 0;
  }

  .people-pages {
    display: flex;
    padding: 7px 15px;
    -ms-flex-align: center;
    align-items: center;
  }

  .people-pages .left {
    min-height: 30px;
    -ms-flex: 1;
    flex: 1;
    text-align: left;
  }

  .people-pages .right {
    min-height: 30px;
    -ms-flex: 1;
    flex: 1;
    text-align: right;
  }
  .people-pages .info {
    text-align: center;
    -ms-flex: 2;
    flex: 2;
    color: #999999;
  }

  .participant-list ul li p {
    margin-bottom: 0;
  }

  .option-wrapper {
    flex-grow: 1 !important;
    padding: 0 !important;
    position: relative;

    .bar-contain {
      padding: 0 !important;
      z-index: 9;
      position: absolute;
      width: 100%;
      height: 100%;
      background: #ffffff;

      .bar {
        position: absolute;
        height: 100%;
        width: 100%;
        transition: all 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
        border-radius: 0 2px 2px 0;
        background-image: linear-gradient(89deg, rgba(237, 100, 68, 0.01) 1%, rgba(236, 94, 61, 0.39) 100%);
      }
    }

    .option-label {
      z-index: 10;
      position: relative;
    }

    .option-label-votes {
      z-index: 10;
      display: block;
      position: relative;
      font-size: 0.7em;
    }
  }
}

.hide-fullscreen button.vjs-fullscreen-control {
  display: none !important;
}

.event-title {
  width: calc(100% - 160px);
}

.CEUToken {
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  height: 70px;
  z-index: 99999;

  div {
    position: sticky;
    padding: 5px;
    background-color: rgba(200, 0, 0, 1);
    color: white;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    h3 {
      margin-bottom: 0;
    }
  }
}
