/*------------------------------------
  Header On Scroll
------------------------------------*/

.u-header--white-bg-on-scroll {
  &.u-scrolled {
    .u-header__section {
      background-color: $white;
      box-shadow: $header-box-shadow;
    }
  }
}