/*------------------------------------
  Text Buttons Styles
------------------------------------*/

@each $color, $value in $theme-colors {
	/* #{$color} text button */
  .btn-text-#{$color} {
    @if $color == "light" {
      @include button-text($value, $gray-400);
    } @else {
      @include button-text($value, $dark);
    }
  }
}