// Border-radius

@mixin border-top-right-radius($hs-radius) {
  @if $enable-rounded {
    border-top-right-radius: $hs-radius;
  }
}

@mixin border-top-left-radius($hs-radius) {
  @if $enable-rounded {
    border-top-left-radius: $hs-radius;
  }
}

@mixin border-bottom-right-radius($hs-radius) {
  @if $enable-rounded {
    border-bottom-right-radius: $hs-radius;
  }
}

@mixin border-bottom-left-radius($hs-radius) {
  @if $enable-rounded {
    border-bottom-left-radius: $hs-radius;
  }
}