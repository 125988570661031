/*------------------------------------
  Mockup Devices v4
------------------------------------*/

.u-devices-v4 {
	transform: rotate(-25deg);

	&__content {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 700px;
		transform-origin: 100% 0;
		transform: translateY(-50%);
	}

	&__laptop {
		width: 35.9375rem;
		height: 20.75rem;
	}

	&__tablet {
		&,
		&-ver {
			&-svg {
				@include border-radius(1.375rem);
				box-shadow: 0 0 .625rem .375rem rgba($dark, .05);
			}
		}
	}

	&__tablet {
		width: 17.1875rem;
		height: 25.4375rem;
		margin-left: 1.5625rem;
	}

	&__tablet-ver {
		width: 25rem;
		height: 16.875rem;
	}

	&__phone {
		width: 6.25rem;
		height: 13.0625rem;
		margin-left: 1.5625rem;

		&-svg {
			@include border-radius(1rem);
			box-shadow: 0 0 .625rem .375rem rgba($dark, .05);
		}
	}
}