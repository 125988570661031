/*------------------------------------
  Background Image Style
------------------------------------*/

.bg-img-hero {
  @include background-cover(cover, no-repeat, top, center);
}

.bg-img-hero-bottom {
  @include background-cover(cover, no-repeat, bottom, center);
}

.bg-img-hero-center {
  @include background-cover(cover, no-repeat, center, center);
}

.bg-img-hero-fixed {
  @include background-cover(cover, no-repeat, center, center);
  background-attachment: fixed;
}