/*------------------------------------
  Area Chart
------------------------------------*/

.u-area-chart {
	position: relative;
	line-height: 0;

	.ct-point {
    stroke-width: .375rem;
  }

	&__tooltip {
		color: $gray-700;
		background-color: $white;
		@include border-radius($border-radius);
		box-shadow: 0 .375rem .9375rem .0625rem rgba($gray-600, .2);
		padding: 1rem;
		font-size: .75rem;
		font-weight: 400;
		font-family: $font-family-sans-serif;

		&::before {
			border: 8px $white;
			margin-left: -.5rem;
		}

		.chartist-tooltip-value {
			font-weight: 600;
		}
	}
}