#exhibitUpload .k-upload-button {
  line-height: 0.6 !important;
}

#exhibit {
  .main-container {
    background-color: #fff;
  }

  .chat-sidebar {
    @include media-breakpoint-up(xl) {
      -ms-flex: 0 0 500px;
      flex: 0 0 500px;
      background-color: #333;
    }

    @include media-breakpoint-up(xxl) {
      -ms-flex: 0 0 700px;
      flex: 0 0 700px;
      background-color: #333;
    }

    @include media-breakpoint-down(lg) {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      background-color: #fafafa;
    }
  }

  .exhibit-container {
    @include media-breakpoint-up(xl) {
      height: calc(100vh - 120px);
      overflow-y: scroll;
    }
  }

  .exhibit-video {
    background-color: #000;
    @include media-breakpoint-up(xl) {
      height: calc((100vh * 0.4) - 44px);
    }
    @include media-breakpoint-down(lg) {
      height: 400px;
    }
  }

  .full-screen-wrapper:fullscreen .exhibit-video {
    width: 100% !important;
    height: calc(100% - 60px) !important;
  }

  .full-screen-wrapper:fullscreen .device-settings-button {
    display: none;
  }

  .chat-collapsed {
    @include media-breakpoint-up(xl) {
      display: none;
    }
  }

  .hide-chat-tab {
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  .hide-when-small {
    @include media-breakpoint-down(lg) {
      visibility: hidden;
    }
  }

  .live-footer {
    @include media-breakpoint-up(xl) {
      position: absolute;
    }

    bottom: 0;
    width: 100%;
    height: 60px;
    line-height: 60px;
    background-color: #333;
  }

  .chat-button-wrapper {
    position: absolute;
    left: 290px;
    text-align: right;
    z-index: 9999999999;
  }

  .collapse-chat-btn {
    visibility: hidden;
  }

  .chat-sidebar:hover .collapse-chat-btn {
    @include media-breakpoint-up(xl) {
      visibility: visible;
    }
  }

  .live-event-link-active::after {
    content: '';
    display: block;
    width: 100%;
    border-bottom: 2px solid;
    margin: 0 auto;
    transition: all 0.3s linear 0s;
  }

  .popover-window {
    @include media-breakpoint-up(xl) {
      width: 500px;
      position: absolute;
      bottom: 60px;
      right: 333px;
    }

    @include media-breakpoint-up(xxl) {
      right: 700px;
    }

    z-index: 1050;

    @include media-breakpoint-down(lg) {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
    }
  }

  .popover-window div.card {
    @include media-breakpoint-down(lg) {
      // height: 400px;
    }
  }

  .popover-window header.card-header {
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  .popover-window div.card-body {
    height: calc((100vh / 2) - 100px);
    overflow-y: scroll;
    background-color: #fafafa;
  }

  .live-messages {
    overflow-y: scroll;

    @include media-breakpoint-down(lg) {
      padding: 10px;
      height: calc(100vh / 2);
    }
    @include media-breakpoint-up(xl) {
      height: calc((100vh * 0.6) - 125px);
      padding: 5px;
      padding-top: 12px !important;
    }
  }

  .live-messages ul {
    list-style: none;
    padding-left: 0;
  }

  .live-messages ul li {
    display: inline-block;
    clear: both;
    float: left;
    margin-left: 2px;
    width: calc(100% - 3px);
    font-size: 0.9em;
    padding-left: 0;
  }
  .live-messages ul li.sent {
    margin-top: 10px;
  }

  .live-messages ul li.sent .avatar {
    float: right;
    margin-left: 5px;
    margin-top: -20px;
  }

  .live-messages ul li.replies .avatar {
    float: left;
    margin-right: 5px;
    margin-top: 0px;
  }

  .live-messages ul li.sent p {
    float: right;
    background: #ffffff;
    color: #000000;
  }

  .live-messages ul li.replies p {
    color: #000000;
  }

  .message-content {
    overflow-wrap: anywhere;
    word-break: break-word;

    @include media-breakpoint-down(lg) {
      padding: 8px 12px;
      line-height: 18px;
      font-size: 14px;
      word-wrap: break-word;
      white-space: pre-line;
      box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.03), 0px 1px 2px 0px rgba(0, 0, 0, 0.16);
      border-radius: 4px;
      background-color: #fff;
      position: relative;
      max-width: 100%;
    }
  }

  .live-messages ul li.replies span {
    color: #999999;
    width: 100%;
    text-align: left;
    display: block;
    padding-right: 2px;
  }

  .live-messages ul li p {
    background: #fff;
    display: inline-block;
    padding: 10px 15px;
    border-radius: 5px;
    max-width: 75%;
    line-height: 130%;
  }

  .live-messages ul li p a {

    text-decoration: underline;
  }

  .nowrap {
    flex-wrap: nowrap;
  }

  .poster {
    height: auto !important;
  }

  #webRTCLayoutContainer {
    position: relative;
    background-color: #000;
    border-top: 1px solid #333;

    // @include media-breakpoint-up(xl) {
    //   height: calc(100% - 60px);
    // }

    // @include media-breakpoint-down(lg) {
    //   height: calc(50vh - 60px);
    // }
  }

  #webRTCLayoutContainer > div {
    background-color: #000;
    display: inline-block;
    border: 1px solid #fff;

    transition-property: all;
    transition-duration: 0.5s;
  }

  #webRTCLayoutContainer > div {
    width: 0;
    height: 0;
    opacity: 0;
  }

  #webRTCLayoutContainer > div.ot-layout {
    opacity: 1;
  }

  .green-room-message {
    padding-bottom: 25px;
    padding-top: 25px;
    position: absolute;
    z-index: 999999;
    width: 100%;
  }

  .no-chats {
    position: absolute;
    bottom: 46px;
    text-align: center;
    font-size: 2em;
    color: #989898;
    width: 100%;
    font-family: Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  .participant-list,
  .card-content {
    margin: 18px 15px 0px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
  }
  .participant-list div {
    padding: 10px;
    background-color: white;
  }

  .participant-list ul {
    list-style: none;
    padding-left: 0;
  }

  .participant-list ul li p {
    margin-bottom: 0;
  }
}
