/*------------------------------------
  Badge Positions
------------------------------------*/

.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
}

.badge-pos {
  &--top-left {
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);
  }

  &--bottom-left {
    bottom: 0;
    left: 0;
    transform: translate(-50%, 50%);
  }

  &--bottom-right {
    bottom: 0;
    right: 0;
    transform: translate(50%, 50%);
  }
}

/*------------------------------------
  Badge in Avatar Component
------------------------------------*/

/* Default Size Avatar */
.u-avatar .rounded-circle {
  + .badge-xs.badge-pos {
    &:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
      transform: translate(20%, -20%);
    }

    &--top-left {
      transform: translate(-20%, -20%);
    }

    &--bottom-left {
      transform: translate(-20%, 20%);
    }

    &--bottom-right {
      transform: translate(20%, 20%);
    }
  }

  + .badge-sm.badge-pos {
    &:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
      transform: translate(25%, -25%);
    }

    &--top-left {
      transform: translate(-25%, -25%);
    }

    &--bottom-left {
      transform: translate(-25%, 25%);
    }

    &--bottom-right {
      transform: translate(25%, 25%);
    }
  }

  + .badge-md.badge-pos {
    &:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
      transform: translate(30%, -30%);
    }

    &--top-left {
      transform: translate(-30%, -30%);
    }

    &--bottom-left {
      transform: translate(-30%, 30%);
    }

    &--bottom-right {
      transform: translate(30%, 30%);
    }
  }
}

/* Small Size Avatar */
.u-sm-avatar .rounded-circle {
  + .badge-xs.badge-pos {
    &:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
      transform: translate(25%, -25%);
    }

    &--top-left {
      transform: translate(-25%, -25%);
    }

    &--bottom-left {
      transform: translate(-25%, 25%);
    }

    &--bottom-right {
      transform: translate(25%, 25%);
    }
  }

  + .badge-sm.badge-pos,
  + .badge-md.badge-pos {
    &:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
      transform: translate(35%, -35%);
    }

    &--top-left {
      transform: translate(-35%, -35%);
    }

    &--bottom-left {
      transform: translate(-35%, 35%);
    }

    &--bottom-right {
      transform: translate(35%, 35%);
    }
  }
}

/* Large Size Avatar */
.u-lg-avatar .rounded-circle {
  + .badge-xs.badge-pos {
    &:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
      transform: translate(-35%, 35%);
    }

    &--top-left {
      transform: translate(35%, 35%);
    }

    &--bottom-left {
      transform: translate(35%, -35%);
    }

    &--bottom-right {
      transform: translate(-35%, -35%);
    }
  }

  + .badge-sm.badge-pos {
    &:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
      transform: translate(-15%, 15%);
    }

    &--top-left {
      transform: translate(15%, 15%);
    }

    &--bottom-left {
      transform: translate(15%, -15%);
    }

    &--bottom-right {
      transform: translate(-15%, -15%);
    }
  }

  + .badge-md.badge-pos {
    &:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
      transform: translate(0%, 0%);
    }

    &--top-left {
      transform: translate(0%, 0%);
    }

    &--bottom-left {
      transform: translate(0%, 0%);
    }

    &--bottom-right {
      transform: translate(0%, 0%);
    }
  }
}

/* Extra Large Size Avatar */
.u-xl-avatar .rounded-circle {
  + .badge-xs.badge-pos {
    &:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
      transform: translate(-100%, 100%);
    }

    &--top-left {
      transform: translate(100%, 100%);
    }

    &--bottom-left {
      transform: translate(100%, -100%);
    }

    &--bottom-right {
      transform: translate(-100%, -100%);
    }
  }

  + .badge-sm.badge-pos {
    &:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
      transform: translate(-65%, 65%);
    }

    &--top-left {
      transform: translate(65%, 65%);
    }

    &--bottom-left {
      transform: translate(65%, -65%);
    }

    &--bottom-right {
      transform: translate(-65%, -65%);
    }
  }

  + .badge-md.badge-pos {
    &:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
      transform: translate(-45%, 45%);
    }

    &--top-left {
      transform: translate(45%, 45%);
    }

    &--bottom-left {
      transform: translate(45%, -45%);
    }

    &--bottom-right {
      transform: translate(-45%, -45%);
    }
  }
}

/*------------------------------------
  Badge in Icon Component
------------------------------------*/

/* Default Size Icon */
.btn-icon.rounded-circle {
  .badge-xs.badge-pos {
    &:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
      transform: translate(20%, -20%);
    }

    &--top-left {
      transform: translate(-20%, -20%);
    }

    &--bottom-left {
      transform: translate(-20%, 20%);
    }

    &--bottom-right {
      transform: translate(20%, 20%);
    }
  }

  .badge-sm.badge-pos {
    &:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
      transform: translate(25%, -25%);
    }

    &--top-left {
      transform: translate(-25%, -25%);
    }

    &--bottom-left {
      transform: translate(-25%, 25%);
    }

    &--bottom-right {
      transform: translate(25%, 25%);
    }
  }

  .badge-md.badge-pos {
    &:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
      transform: translate(30%, -30%);
    }

    &--top-left {
      transform: translate(-30%, -30%);
    }

    &--bottom-left {
      transform: translate(-30%, 30%);
    }

    &--bottom-right {
      transform: translate(30%, 30%);
    }
  }
}

/* Small Size Icon */
.btn-sm.rounded-circle {
  .badge-xs.badge-pos {
    &:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
      transform: translate(25%, -25%);
    }

    &--top-left {
      transform: translate(-25%, -25%);
    }

    &--bottom-left {
      transform: translate(-25%, 25%);
    }

    &--bottom-right {
      transform: translate(25%, 25%);
    }
  }

  .badge-sm.badge-pos,
  .badge-md.badge-pos {
    &:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
      transform: translate(35%, -35%);
    }

    &--top-left {
      transform: translate(-35%, -35%);
    }

    &--bottom-left {
      transform: translate(-35%, 35%);
    }

    &--bottom-right {
      transform: translate(35%, 35%);
    }
  }
}

/* Large Size Icon */
.btn-lg.rounded-circle {
  .badge-xs.badge-pos {
    &:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
      transform: translate(-35%, 35%);
    }

    &--top-left {
      transform: translate(35%, 35%);
    }

    &--bottom-left {
      transform: translate(35%, -35%);
    }

    &--bottom-right {
      transform: translate(-35%, -35%);
    }
  }

  .badge-sm.badge-pos {
    &:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
      transform: translate(-15%, 15%);
    }

    &--top-left {
      transform: translate(15%, 15%);
    }

    &--bottom-left {
      transform: translate(15%, -15%);
    }

    &--bottom-right {
      transform: translate(-15%, -15%);
    }
  }

  .badge-md.badge-pos {
    &:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
      transform: translate(0%, 0%);
    }

    &--top-left {
      transform: translate(0%, 0%);
    }

    &--bottom-left {
      transform: translate(0%, 0%);
    }

    &--bottom-right {
      transform: translate(0%, 0%);
    }
  }
}