/* using Sass */
// @import 'myTheme';
// @import '~@progress/kendo-theme-bootstrap/scss/all';
// @import '~bootstrap/scss/bootstrap';
// @import 'homepage';
// @import 'footer';
// @import 'profile';
// @import 'enumColors';
// @import 'myApplications';
// @import 'spinners';

html {
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}

.card-deck {
  margin-bottom: 5px;
}
.card-deck .card {
  margin-right: 5px;
  margin-left: 5px;
}

.card-body {
  padding: 5px;
}

p.emp-type {
  font-size: 10px;
  margin-bottom: 0;
}

.invalid-feedback-select {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.job-pipeline div {
  padding: 5px !important;
  cursor: pointer;
}

.job-pipeline div:hover {
  background: #ccc;
  color: white;
}

.job-pipeline div.selected {
  background: #666;
  color: white;
}

.green-switch.k-switch-on {
  background-color: #28a745 !important;
}
.green-switch.k-switch-on .k-switch-container {
  background-color: #28a745 !important;
}

.dropdown-item i.dropdown-check {
  visibility: hidden;
}

.dropdown-item.selected i.dropdown-check {
  visibility: visible;
}

/* VALIDATION STYLING */
.corkyyDropdown {
  width: 100%;
}

.k-dateinput-wrap .k-select,
.k-dropdown-wrap .k-select,
.k-picker-wrap .k-select,
.k-numeric-wrap .k-select,
.input-group .k-dropdown-wrap,
.input-group .k-multiselect-wrap,
.input-group .k-maskedtextbox .k-textbox {
  border-radius: 0 0.25rem 0.25rem 0;
}

.input-group .k-dateinput-wrap,
.input-group .k-picker-wrap {
  border-radius: 0.25rem;
}

.form-control,
.select-group .custom-select {
  border-radius: 0.25rem;
  border: 1px solid #ced4da;
  padding-right: 30px;
}

.corkyyDropdown.k-combobox .k-dropdown-wrap,
/*.k-multiselect-wrap,*/
.k-numeric-wrap,
.k-picker-wrap,
.k-dropdown-wrap {
  border: none;
  border-radius: 0.25rem;
}

.input-group .k-numeric-wrap,
.corkyyDropdown.ng-pristine {
  border-radius: 0.25rem;
}

.popup-item {
  width: 100%;
  display: block;
  float: none;
  position: relative;
}

.form-control.ng-pristine.ng-invalid.ng-touched .corkyyDropdown.ng-invalid.ng-dirty kendo-combobox,
.form-control.ng-dirty.ng-invalid.ng-untouched,
.form-control.ng-dirty.ng-invalid.ng-touched,
.corkyyDropdown.ng-untouched.ng-invalid.ng-dirty kendo-combobox,
.corkyyDropdown.ng-touched.ng-dirty.ng-invalid kendo-combobox,
.corkyyDropdown.ng-untouched.ng-invalid.ng-dirty kendo-multiselect,
.corkyyDropdown.ng-touched.ng-dirty.ng-invalid kendo-multiselect,
.corkyyDropdown.ng-touched.ng-dirty.ng-invalid select,
.corkyyDropdown.ng-untouched.ng-invalid.ng-dirty select,
.pinnacleAutocomplete.ng-untouched.ng-invalid.ng-dirty,
.invalid-border input.form-control {
  border: 1px solid #dc3545;
  border-radius: 0.25rem;
}

kendo-editor.ng-touched.ng-dirty.ng-invalid,
kendo-editor.ng-untouched.ng-dirty.ng-invalid {
  border: 1px solid #dc3545;
}

kendo-switch.form-control,
.form-control.ng-invalid.ng-dirty .k-dropdown-wrap,
.corkyyDropdown,
.select-group {
  border: none;
}

.invalid-message {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #bb3734;
}

.invalid-message-visible {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #bb3734;
}

.ng-dirty.ng-invalid + .invalid-message {
  display: block;
}
/* End Validation Styling */

.k-switch {
  max-width: 50px;
  font-size: 0.6rem;
}

.k-switch-handle {
  width: 1.5em;
  height: 1.5em;
  color: #fff;
  background-color: #fff;
  border: none;
}

.k-switch-container {
  width: 40px;
  display: inline-flex;
  height: 20px;
  align-self: center;
}

.k-widget.form-control.k-switch {
  background: transparent;
  justify-content: center;
  display: inline-flex;
  box-shadow: none;
}

.k-switch.sib-w-label {
  margin-top: 20px;
}

.k-switch-label-on,
.k-switch-label-off {
  display: none;
}

.k-switch-on .k-switch-handle,
.k-switch-off .k-switch-handle {
  border-color: #fff;
  background-color: #fff;
}

.k-switch-on .k-switch-container {
  background-color: #4e8e3b;
}

.k-switch-off .k-switch-container {
  background-color: #9d9da0;
}

.k-switch-off .k-switch-handle {
  background-color: #fff;
  border: none;
}

.sib-w-label.k-switch-label {
  margin-top: 40px;
  margin-right: 10px;
  font-size: 14px;
  font-weight: 500;
  margin-left: 5px;
}

.k-content > .ProseMirror {
  padding: 5px;
}

.dropdown-menu {
  z-index: 1025;
}

.k-switch-on .k-switch-handle {
  left: calc(100% - 1.5em);
}

i.fa.fa-asterisk {
  color: #bb3734;
  font-size: 10px;
  margin-left: 2px;
}

.settings-sidebar {
  -ms-flex: 0 0 280px;
  flex: 0 0 280px;
}

.btn-bs-file {
  position: relative;
}
.btn-bs-file input[type='file'] {
  position: absolute;
  top: -9999999;
  filter: alpha(opacity=0);
  opacity: 0;
  width: 0;
  height: 0;
  outline: none;
  cursor: inherit;
}

.signup-header {
  background-image: url('/../assets/registration-splash.png');
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

.xl-modal .modal-dialog {
  max-width: 80%;
  width: 80%;
}

div[ng-reflect-router-link] {
  cursor: pointer;
}

@include media-breakpoint-down(xs) {
  .job-actions {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.pipeline-border {
  @include media-breakpoint-down(xs) {
    border-bottom: 1px solid #dee2e6 !important;
  }
  @include media-breakpoint-up(sm) {
    border-right: 1px solid #dee2e6 !important;
  }
}

@include media-breakpoint-up(sm) {
  #overlay {
    position: fixed; /* Sit on top of the page content */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
    z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer; /* Add a pointer on hover */
    pointer-events: none;
  }

  .card.expose:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 0;
    height: 0;
    border-top: solid 25px #fff;
    border-left: solid 25px transparent;
    border-right: solid 25px transparent;
  }

  .expose {
    z-index: 3 !important;
    position: relative !important;
    background-color: #fff;
  }
}

.cursor-pointer {
  cursor: pointer !important;
}

span.newline {
  display: inline-block;
}

.no-candidates {
  text-align: center;
  border: 2px dashed #ccc;
  border-radius: 15px;
}

.applicant-list {
  @include media-breakpoint-up(md) {
    height: 750px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}

.applicant-list .active {
  background-color: #c9c9c9;
  border-color: #c9c9c9;
  color: #000;
}

.recommend-count {
  font-size: 0.8em;
  padding-top: 0.38em;
  padding-left: 0.5em;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !important;
  font-weight: 700 !important;
}

.dropdown-no-arrow.dropdown-toggle::after {
  display: none;
}

.checkbox label:after {
  content: '';
  display: table;
  clear: both;
}

.checkbox .cr {
  position: relative;
  display: inline-block;
  border: 1px solid #a9a9a9;
  border-radius: 0.25em;
  width: 2em;
  height: 2em;
  float: left;
  margin-right: 0.5em;
}

.checkbox .cr .cr-icon {
  position: absolute;
  font-size: 1.5em;
  line-height: 0;
  top: 50%;
  left: 10%;
}

.checkbox label input[type='checkbox'] {
  display: none;
}

.checkbox label input[type='checkbox'] + .cr > .cr-icon {
  opacity: 0;
}

.checkbox label input[type='checkbox']:checked + .cr > .cr-icon {
  opacity: 1;
}

.checkbox label input[type='checkbox']:disabled + .cr {
  opacity: 0.5;
}

div.pac-container {
  z-index: 1500 !important;
}

.btn-huge,
.btn-group-huge > .btn {
  padding: 0.5rem 1rem;
  font-size: 2.25rem;
  line-height: 1.5;
  border-radius: 0.5rem;
}

.showBreakpoint::after {
  @include media-breakpoint-only(xs) {
    content: ' xs';
  }
  @include media-breakpoint-only(sm) {
    content: ' sm';
  }
  @include media-breakpoint-only(md) {
    content: ' md';
  }
  @include media-breakpoint-only(lg) {
    content: ' lg';
  }
  @include media-breakpoint-only(xl) {
    content: ' xl';
  }
  @include media-breakpoint-only(xxl) {
    content: ' xxl';
  }
}

.hide-arrow::after {
  display: none !important;
}

.list-group-padded li.list-group-item {
  padding: 0.75rem 1.25rem !important;
}

// Medium Devices
@include media-breakpoint-down(md) {
  .font-size-md-down-1 {
    font-size: 1rem;
  }

  .font-size-md-down-2 {
    font-size: 1.4rem;
  }
}

.ng5-slider-ceil,
.ng5-slider-floor {
  visibility: hidden !important;
}

.ng5-slider .ng5-slider-pointer,
.ng5-slider .ng5-slider-selection {
  background-color: $primary !important;
}

.ng5-slider .ng5-slider-pointer.ng5-slider-active:after {
  background-color: $orange-lighter !important;
}

.u-sidebar {
  z-index: 1021 !important;
}

.sticky-top {
  z-index: 1000 !important;
}

kendo-editor > div > div {
  min-height: 300px;
}

div.img-button-overlay {
  position: relative;
  float: left;
  margin: 5px;
}
div.img-button-overlay:hover img {
  opacity: 0.5;
}

div.img-button-overlay:hover .img-button-overlay-btn {
  display: block;
}
div.img-button-overlay .img-button-overlay-btn {
  position: absolute;
  display: none;
}
div.img-button-overlay .img-button-overlay-btn {
  top: 5px;
  left: 5px;
}

li.nav-item.active {
  border-bottom: 2px solid $primary;
}

.share-not-linked {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin-top: auto;
  background: rgba(112, 112, 112, 0.95);
}

.hs-sub-menu {
  display: block !important;
  box-shadow: $dropdown-box-shadow !important;
  @include media-breakpoint-down(md) {
    position: static !important;
    visibility: visible !important;
  }
}

@include media-breakpoint-up(md) {
  .text-right-md {
    text-align: right !important;
  }
}

input,
button,
textarea,
select {
  -webkit-transform: translate3d(0, 0, 0);
}

.sticky-top {
  z-index: 3 !important;
}

.vplayer-dimensions {
  height: auto !important;
  width: auto !important;
}

.amp-content-title-ad {
  display: none;
}

//  .azuremediaplayer div button.vjs-big-play-button{
//    visibility: hidden !important;
//  }
//  .azuremediaplayer div:hover:not(.vjs-playing) button.vjs-big-play-button{
//    visibility: visible !important;
//  }

.border-radius-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

div.white-hover:hover {
  color: red !important;
}

.hide-player {
  @include media-breakpoint-down(md) {
    display: none !important;
  }
}

.assessment {
  position: absolute;
  @include media-breakpoint-down(md) {
    position: relative !important;
  }
}

.segments {
  overflow: scroll;

  @include media-breakpoint-down(md) {
    overflow: unset;
  }
}

.vjs-error-display {
  display: none !important;
}

#livePLayer.embed-responsive {
  border: 1px solid black;
}

.star-rating:hover .dropdown-menu {
  display: block;
}

#layoutContainer {
  position: relative;
  height: 40%;
  min-height: 300px;
  background-color: #ddd;
}

#layoutContainer > div {
  background-color: #000;
  display: inline-block;
  border: 1px solid #fff;

  transition-property: all;
  transition-duration: 0.5s;
}

#layoutContainer > div {
  width: 0;
  height: 0;
  opacity: 0;
}

#layoutContainer > div.ot-layout {
  opacity: 1;
}

.input-group-append button {
  border: 1px solid #ced4da;
}

.modal-full .modal-dialog {
  max-width: 90% !important;
}

.vbc-update .flex {
  display: flex;
}
.video-thumb {
  position: relative;
}
.video-btn {
  cursor: pointer;
}

.video-caption {
  background-color: #3fafa7;
  width: 100%;
  color: #032525;
  text-transform: uppercase;
  position: relative;
  height: 80px;
}
.text-container {
  margin-top: auto;
  margin-bottom: auto;
}

.video-caption .smoler-text {
  margin-left: 24px;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 2.39px;
  line-height: 20px;
  text-align: left;
  padding-top: 16.5px;
}

.stock {
  position: relative;
  overflow: visible !important;
  cursor: pointer;
}

.stock figure {
  display: none;
  position: absolute;
  top: 0;
  left: 100px;
  padding: 0;
  margin: 0;
  border: 5px solid #fd0;
  background: #fd0;
  border-radius: 1em;
  z-index: 9999;
}

.stock:focus figure,
.stock:hover figure {
  display: block;
}

.stock img {
  display: block;
  border-radius: 0.8em;
  width: 900px;
  height: 340px;
}

.k-grid-aria-root {
  overflow: visible !important;
}

.k-grid-container {
  overflow: visible !important;
}

.k-grid-content {
  overflow: visible !important;
}

.k-grid .no-padding {
  padding: 0;
  overflow: visible !important;
}

.whole-cell {
  display: block;
  padding: 11px 12px; /* depends on theme */
}

.in-the-money {
  background-color: #d4e8f7;
}

.dance-with-devil {
  @include media-breakpoint-up(md) {
    background-color: #ffbdc9 !important;
  }

  @include media-breakpoint-down(sm) {
    border: 1px solid red;
    background-color: #fff4fa;
  }
}

// turn tables into cards at certain breakpoints
// mobile-table has one column per row at small, mobile-xs-table keeps two columns per row at small

.mobile-grid-label {
  display: none;
}

.grid-label {
  display: block;
  font-size: 11px;
  text-transform: uppercase;
  color: #9ca1ac;
  font-weight: bold;
}

@include media-breakpoint-down(md) {
  .mobile-table tr td:first-child,
  .mobile-xs-table tr td:first-child {
    padding: 10px 0px;
  }

  .mobile-table tr td,
  .mobile-xs-table tr td {
    vertical-align: top;
    width: calc(50% - 4px);
    display: inline-block;
    padding: 8px 0;
  }

  .mobile-table tbody tr:last-child,
  .mobile-xs-table tbody tr:last-child {
    border: 1px solid #ced4da;
  }

  .mobile-grid-label {
    display: block;
    font-size: 11px;
    text-transform: uppercase;
    color: #9ca1ac;
    font-weight: bold;
  }

  .mobile-table tr td span.mobile-grid-label,
  .mobile-xs-table tr td span.mobile-grid-label {
    display: block;
    font-size: 11px;
    text-transform: uppercase;
    color: #9ca1ac;
    font-weight: bold;
  }

  .mobile-table tr,
  .mobile-xs-table tr {
    background: #fff;
    padding: 8px 15px;
    display: block;
    box-sizing: border-box;
    position: relative;
    border: 1px solid #ced4da;
    border-radius: 8px;
    margin: 0 auto 12px;
  }

  .mobile-table tr td,
  .mobile-xs-table tr td {
    border-top: 0;
  }

  .mobile-table tr.header-row,
  .mobile-xs-table tr.header-row {
    display: none;
  }

  .mobile-table tr td,
  .mobile-xs-table tr td {
    word-break: normal;
  }
}

@include media-breakpoint-down(sm) {
  .mobile-table tr td {
    width: 100%;
    display: block;
    padding: 6px 0;
  }
}

.tray {
  @include media-breakpoint-up(lg) {
    display: flex;
    height: 30px;
  }

  @include media-breakpoint-down(md) {
    display: block;
    padding-left: 2em;
  }

  padding: 5px 0;
  background-color: #f6f6f6;
}

.index-symbol {
  font-weight: 500;
  margin-right: 0.5em;
}

.tray .indices {
  @include media-breakpoint-up(lg) {
    display: flex;
  }

  @include media-breakpoint-down(md) {
    display: block;
  }
  white-space: nowrap;
}

.tray .indices a {
  display: block;
  margin: 0 20px 0 0;
  color: #444;
}

.market-time {
  color: #767676;
  white-space: nowrap;
}

.change.loss {
  color: #c00;
  margin-left: 0.3em;
}

.change.gain {
  color: #4e8e3b;
  margin-left: 0.3em;
}

.tray > :first-child {
  @include media-breakpoint-up(lg) {
    margin-left: 3em;
  }
}

.percentChange {
  margin-left: 0.3em;
}

.futures {
  margin-right: 8px;
}

.color-green {
  color: #28a745;
}

.color-red {
  color: #e74c3c;
}

.red-600 {
  color: #ff4c52 !important;
}

.font-size-18 {
  font-size: 18px !important;
}

.card-subtitle,
.card-text:last-child {
  margin-bottom: 0;
}

.stock-invest {
  .badge {
    font-weight: 100;
  }

  .w-150 {
    width: 150px !important;
  }

  .badge-default {
    color: #212529;
    background-color: #e4eaec;
    color: #76838f;
  }

  .font-size-14 {
    font-size: 14px !important;
  }

  .mb-10 {
    margin-bottom: 10px !important;
  }

  .mt-10 {
    margin-top: 10px !important;
  }

  span.badge.mb-10 {
    margin-bottom: 10px !important;
  }
  span.badge.mb-5 {
    margin-bottom: 5px !important;
  }

  span.badge.w-100 {
    width: 100px !important;
  }

  .bg-light-green-500 {
    background-color: #7eb524 !important;
  }

  .font-weight-500 {
    font-weight: 500 !important;
  }

  .hidden-md-up {
    display: none;
  }
  .offer-warning {
    border-color: #ffc107;
  }
  .offer-radius {
    border-radius: 5px;
  }
  .offer {
    background: #fff;
    border: 1px solid #ddd;
    display: none;
    // box-shadow: 0 10px 20px rgb(0 0 0 / 20%);
    margin: 15px 0;
    overflow: hidden;
    height: 100%;
    cursor: pointer;
  }

  .offer-warning .shape {
    border-color: transparent #ffc107 transparent transparent;
  }

  .shape {
    border-color: transparent #d9534f transparent transparent;
  }

  .bg-yellow-500 {
    background-color: #ffdc2e !important;
  }

  .color-orange {
    color: #ffc107;
  }

  .shape {
    border-style: solid;
    border-width: 0 60px 60px 0;
    float: right;
    height: 0;
    width: 0;
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }

  .shape-text {
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    position: relative;
    right: -30px;
    top: -2px;
    white-space: nowrap;
    -webkit-transform: rotate(46deg);
    transform: rotate(46deg);
  }

  .offer-content {
    padding: 10px 20px;
    margin-bottom: 20px;
  }

  .white {
    color: #fff !important;
  }
}

.D\+,
.D\-,
.E\+,
.E\-,
.D,
.E {
  color: $error;
}

.C\+,
.C\-,
.C {
  color: $warning;
}

.A\+,
.A\-,
.B\+,
.B\-,
.A,
.B {
  color: $success;
}
